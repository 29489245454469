<template>
  <div>
    <v-divider class="my-5" />
    <label-icon label="Chakras" icon="bubble_chart" bold />
    <div v-if="hasChakras">
      <v-switch v-model="hideNames" label="Esconder nomes ao paciente" />
      <v-list two-line>
        <v-list-group
          v-for="(diagnoseChakra, index) in diagnoseChakras"
          :key="index"
          class="elevation-1"
        >
          <template v-slot:activator>
            <v-list-item class="pa-7">
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  {{ diagnoseChakra.chakra.name }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <div class="pa-3 black--text body-1">
            <property-value
              property="Localização"
              :value="diagnoseChakra.chakra.location"
            />
            <image-section
              v-if="!!diagnoseChakra.chakra.image"
              :image="diagnoseChakra.chakra.image"
            />
            <select-items-editor
              :value="diagnoseChakra.procedures"
              :items="diagnoseChakra.chakra.procedures"
              return-value="procedures"
              label="Atuações selecionadas"
              :rules="[$rules.arrayNotEmpty]"
              field-name="name"
              class="mb-3"
              @save="changeProperties"
              @open="selectIndex(index)"
            />
            <text-editor
              :value="diagnoseChakra.therapistComments"
              label="Comentários aos terapeutas"
              return-value="therapistComments"
              class="mb-3"
              @save="changeProperties"
              @open="selectIndex(index)"
            />
            <text-editor
              :value="diagnoseChakra.patientComments"
              label="Comentários ao paciente"
              return-value="patientComments"
              class="mb-3"
              @save="changeProperties"
              @open="selectIndex(index)"
            />
            <v-row justify="center" class="py-5">
              <v-btn dark color="red" @click="removeChakra(index)">
                <v-icon class="mr-2">delete</v-icon>
                Remover Chakra
              </v-btn>
            </v-row>
          </div>
        </v-list-group>
      </v-list>
    </div>
    <div v-else class="mt-5">Nenhum chakra adicionado.</div>
  </div>
</template>

<script>
export default {
  name: "ChakrasSection",
  data: () => ({
    selectedIndex: -1,
  }),
  computed: {
    hasChakras() {
      return this.$store.getters["diagnosis/hasChakras"];
    },
    diagnoseChakras() {
      return this.$store.state.diagnosis.diagnose.chakras;
    },
    hideNames: {
      get() {
        return this.$store.state.diagnosis.diagnose.hideNamesChakras;
      },
      set(value) {
        this.$store.commit("diagnosis/setHideNamesChakras", value);
      },
    },
  },
  methods: {
    changeProperties(property) {
      this.$store.commit("diagnosis/changePropertyChakras", {
        index: this.selectedIndex,
        property,
      });
    },
    selectIndex(index) {
      this.selectedIndex = index;
    },
    removeChakra(index) {
      this.$store.commit("diagnosis/removeChakra", index);
    },
  },
};
</script>

<style scoped></style>
